<template>
  <div class="user-profile">
    <div v-if="loading" class="pt-16 user-profile__loader d-flex justify-center align-center">
      <v-progress-circular width="2" indeterminate color="primary" />
    </div>
    <v-row justify="center" class="py-8">
      <v-card v-if="!loading" max-width="800" elevation="2" flat class="rounded-lg">
        <v-card-text class="pa-6">
          <div v-if="!loading" class="text-center">
            <span v-if="!modificando_imagen" class="foto_avatar" @click="editImage">
              <DAvatar
                :text="`${user.name} ${user.surname}`"
                size="150"
                rounded
                :two-letter="true"
                :src="user.fotoUrl"
              />
            </span>
            <croppa
              v-model="myCroppa"
              :class="!modificando_imagen ? estado_croppa + ' foto_avatar' : 'foto_avatar'"
              :width="150"
              :height="150"
              :placeholder="$t('user.select_image')"
              placeholder-color="#1976D2"
              :placeholder-font-size="10"
              canvas-color="transparent"
              :show-remove-button="true"
              remove-button-color="#1976D2"
              :remove-button-size="36"
              :show-loading="true"
              :loading-size="20"
              :zoom-speed="8"
              :prevent-white-space="true"
              :file-size-limit="5002400"
              @file-size-exceed="onFileSizeExceed"
            />
            <input
              id="file"
              ref="file"
              type="file"
              accept="application/pdf,image/jpg,image/png,image/gif"
              style="display: none"
              @change="onFileChange"
            />
          </div>

          <v-form ref="profileForm" v-model="validForm" lazy-validation class="form_datos_personales">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.name"
                  outlined
                  class="mr-2"
                  :label="$t('patient.first_name') + '*'"
                  type="text"
                  required
                  dense
                  :rules="rules.nameOrSurname"
                  @change="handleFormDataChange"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.surname"
                  outlined
                  class="mr-2"
                  :label="$t('patient.last_name') + '*'"
                  type="text"
                  required
                  dense
                  :rules="rules.nameOrSurname"
                  @change="handleFormDataChange"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="user.email"
                  outlined
                  class="mr-2"
                  :label="$t('message.email') + '*'"
                  type="mail"
                  required
                  dense
                  disabled
                  :rules="rules.email"
                  @change="handleFormDataChange"
                />
              </v-col>

              <v-col v-if="isPatient" cols="12" sm="6">
                <div class="pr-2">
                  <v-menu
                    ref="menuBirthDate"
                    v-model="menuBirthDate"
                    :close-on-content-click="false"
                    :return-value.sync="user.birthdate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFormatted"
                        filled
                        dense
                        outlined
                        :label="$t('patient.birthdate') + ' *'"
                        append-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        @blur="date = parseDate(dateFormatted)"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="date"
                      no-title
                      scrollable
                      class="input_fnacimiento"
                      first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      :min="datePickerMinDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      @input="$refs.menuBirthDate.save(date)"
                    />
                  </v-menu>
                </div>
              </v-col>

              <v-col v-if="!isPatient && !isReceptionist" cols="12" sm="6">
                <v-text-field
                  v-model="user.phoneConsultation"
                  outlined
                  class="mr-2"
                  :label="$t('user.telefono_secretaria')"
                  dense
                  :rules="rules.phone"
                  @change="handleFormDataChange"
                />
              </v-col>

              <v-col v-if="user.is_health_personnel && !isAdmin && !isPatient" cols="12" sm="6">
                <v-text-field
                  v-model="user.collegiateNumber"
                  outlined
                  class="mr-2"
                  :label="`${$t('user.num_colegiado')}*`"
                  type="text"
                  required
                  dense
                  :rules="checkCollegiateNumberIfCanPrescribe"
                  @change="handleFormDataChange"
                />
              </v-col>

              <v-col v-if="!isAdmin" cols="12" :sm="!user.is_health_personnel && !isPatient ? 6 : 12">
                <v-identify-document
                  :dense="true"
                  :filled="false"
                  :id-document="user.id_document"
                  :id-document-type="user.id_document_type"
                  class="pr-1"
                  :use-required="setRequiredIdConditions"
                  @onError="handleIdErrors"
                  @input="setIdDocument"
                />
              </v-col>

              <v-col v-if="isPatient" cols="12" sm="4">
                <v-tel-field
                  v-model="user.phone"
                  :label="$t('patient.phone') + (isPhoneMandatoryForPatient ? ' *' : '')"
                  :outlined="true"
                  :dense="true"
                  :placeholder="$t('patient.phone')"
                  class="pr-2"
                  :rules="isPhoneMandatoryForPatient ? rules.requiredField : rules.phone"
                  v-bind="bindPropsTel"
                />
              </v-col>
              <v-col v-if="isPatient" cols="12" :sm="user.is_health_personnel ? 6 : 4" class="pr-3">
                <v-gender-select :dense="true" :filled="false" :gender="user.gender" @input="inputGender" />
              </v-col>
              <v-col cols="12" :sm="user.is_health_personnel ? 6 : 4">
                <v-select
                  v-model="user.lang"
                  outlined
                  :items="languages"
                  :label="$t('common.lang_platform')"
                  auto
                  required
                  dense
                  item-text="name"
                  item-value="id"
                  class="pr-2"
                />
              </v-col>

              <v-col v-show="!isAdmin && user.is_health_personnel && !isPatient" cols="12">
                <v-select
                  ref="select"
                  v-model="user.especialidades"
                  outlined
                  :label="$t('user.especialidades')"
                  :items="especialidades"
                  chips
                  item-text="name"
                  item-value="id"
                  item-valor="id"
                  multiple
                  class="pr-2"
                  @change="
                    closeAfterSelect;
                    canPrescribe(especialidades, user.especialidades);
                  "
                >
                  <template slot="selection" slot-scope="data">
                    <v-chip :input-value="data.selected" close small @click:close="removeEspecialidad(data.item.id)">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template slot="item" slot-scope="data">
                    <template v-if="!isObject(data.item)">
                      <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
              </v-col>

              <v-col v-if="!isPatient && !isAdmin && !isReceptionist" cols="12" class="text-right">
                <v-radio-group v-model="user.biography_lang" row class="langs">
                  <label style="margin-top: 5px; margin-right: 5px" class="hidden-xs-only">
                    {{ $t('common.lang_estracto') }}:
                  </label>
                  <v-radio color="blue darken-3" :label="$t('common.spanish')" value="es" />
                  <v-radio color="blue darken-3" :label="$t('common.english')" value="en" />
                </v-radio-group>
              </v-col>

              <v-col v-if="user.biography_lang === 'es' && !isPatient && !isReceptionist" cols="12">
                <v-textarea
                  v-model="user.biography_es"
                  outlined
                  dense
                  :label="$t('user.biografia')"
                  rows="3"
                  placeholder="Escriba aqui el texto en Español"
                  class="pr-2"
                  :rules="rules.maxLengthBio"
                  @change="handleFormDataChange"
                />
                <div
                  :class="500 - user.biography_es.length < 0 ? 'font-red' : ''"
                  style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                >
                  {{ 500 - user.biography_es.length }}
                </div>
              </v-col>

              <v-col v-if="user.biography_lang === 'en' && !isReceptionist" cols="12">
                <v-textarea
                  v-model="user.biography_en"
                  outlined
                  dense
                  :label="$t('user.biografia')"
                  rows="3"
                  placeholder="Write here the text in English"
                  class="pr-2"
                  :rules="rules.maxLengthBio"
                  @change="handleFormDataChange"
                />
                <div
                  :class="500 - user.biography_en.length < 0 ? 'font-red' : ''"
                  style="font-size: 11px;margin-top: -20px;width: 99.5%;"
                >
                  {{ 500 - user.biography_en.length }}
                </div>
              </v-col>
            </v-row>
            <v-row justify="end" class="pr-3" dense>
              <v-btn depressed :disabled="disabledButton || !validForm" color="primary" @click="validate()">
                {{ $t('common.save') }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row justify="center" class="pb-8">
      <v-card v-if="!loading" max-width="800" elevation="2" flat class="rounded-lg px-3 py-3 flex-grow-1">
        <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1">
          {{ $t('change_password.change_password') }}
        </v-card-title>
        <v-card-text>
          <ChangePasswordForm
            :user-id="userId"
            @on-success="passwordChangedSuccessfully"
            @on-error="changePasswordError"
          />
        </v-card-text>
      </v-card>
    </v-row>

    <v-row v-if="!loading && showPersonalDoctorHistoric" justify="center" class="pb-8">
      <v-card max-width="800" elevation="2" flat class="rounded-lg px-3 py-3 flex-grow-1">
        <v-card-text>
          <HistoricPersonalDoctors :patient-id="patientUuid" :company-id="authUser.company_docline_api_id" />
        </v-card-text>
      </v-card>
    </v-row>

    <div class="text-center">
      <!-- Dialog Salir sin guardar -->
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="text-h5 lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog Verify Password -->
      <v-dialog v-model="dialogPassword.show" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 lighten-2">{{ $t('message.intro_pw') }}</v-card-title>
          <v-card-text>
            {{ $t('message.intro_security') }}
            <v-flex xs8 sm8 lg8 offset-xs2 class="mt-6">
              <v-text-field
                v-model="dialogPassword.password"
                outlined
                class="mr-2"
                :label="$t('message.password')"
                append-icon="mdi-lock"
                type="password"
                required
              />
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="cancelCheckPassword">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="checkPassword">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  getProfileUrl,
  saveProfileUrl,
  checkPassword,
  getHeader,
  editImageUserUrl,
  getHeaderMultimedia,
} from '../config/config';
import VGenderSelect from '../modules/VuetifyGender/components/VGenderSelect.vue';
import VIdentifyDocument from '../modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue';
import DAvatar from '../components/DAvatar.vue';
import { mapActions, mapState } from 'vuex';
import FormValidator from '@/mixins/FormValidation';
import { canPrescribe } from '@/services/collegiateHelper';
import { datePickerMinDate } from '@/services/dateHelper';
import { isPhoneMandatoryForPatient } from '@/utils';
import HistoricPersonalDoctors from '@/components/PersonalDoctors/HistoricPersonalDoctors';
import { authUserService } from '@/services/auth-user';

export default {
  components: {
    HistoricPersonalDoctors,
    VIdentifyDocument,
    VGenderSelect,
    DAvatar,
    ChangePasswordForm: () => import('@/components/shared/ChangePasswordForm'),
  },

  mixins: [FormValidator],

  data() {
    return {
      validForm: true,
      dialogSalirSinGuardar: false,
      pageNext: {},
      formIsDirty: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      loading: true,
      timeout: 5000,
      mode: '',
      user: {},
      alerta: {
        texto: '',
      },
      disabledButton: false,
      custom_template:
        window.localStorage.getItem('auth_ehealth') &&
        typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== '',
      isAdmin:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
      isReceptionist:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
      modificando_imagen: false,
      estado_croppa: 'ocultar_croppa',
      myCroppa: {},
      especialidades: [],
      requiredCollegiateNumber: false,
      isPatient: JSON.parse(window.localStorage.getItem('auth_ehealth'))['isPatient'],
      bindPropsTel: {
        mode: 'international',
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'phonePatient',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      date: null,
      dateFormatted: null,
      menuBirthDate: false,
      datePickerMinDate: datePickerMinDate(),
      profileEmail: '',
      dialogPassword: {
        show: false,
        password: '',
      },
      isPhoneMandatoryForPatient: isPhoneMandatoryForPatient(),
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      companyPermissions: state => state.account.authUser.company.permissions,
    }),

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    patientUuid() {
      if (!this.authUser.roles.length) {
        return null;
      }

      return this.authUser.roles[0].docline_api_id;
    },

    isObject() {
      return item => typeof item === 'object';
    },

    userInitials() {
      let initials = '';
      initials += this.user.name ? this.user.name.charAt(0) : '';
      initials += this.user.surname ? this.user.surname.charAt(0) : '';
      return initials.toUpperCase();
    },

    avatarBackgroundColor() {
      const hue = (360 / 26) * (this.userInitials.charCodeAt() - 64);
      return `hsl(${hue}, 68%, 48%)`;
    },

    languages() {
      return [
        { id: 'es', name: vm.$t('common.spanish') },
        { id: 'en', name: vm.$t('common.english') },
      ];
    },

    userId() {
      return this.user.user_id || this.user.id;
    },

    setRequiredIdConditions() {
      return !this.isPatient;
    },

    checkCollegiateNumberIfCanPrescribe() {
      return canPrescribe(this.especialidades, this.user.especialidades) ? this.rules.collegiateNumber : [];
    },

    showPersonalDoctorHistoric() {
      return (
        authUserService.isPatientRol() &&
        this.companyPermissions &&
        this.companyPermissions.personalDoctor &&
        this.companyPermissions.personalDoctor.personalDoctorPatient
      );
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    menuBirthDate(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },

  created() {
    this.getUserData();
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    getUserData() {
      this.$http.post(getProfileUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        this.user = response.data;
        this.profileEmail = response.data.email;
        this.date = this.user.birthdate;
        this.especialidades = this.user.all_especialidades;
        this.loading = false;
        this.user.phone = this.setUserPhone();
      });
    },

    setUserPhone() {
      if (!this.isPatient) return;

      return (this.user.phone && this.user.phone_prefix + this.user.phone) || '';
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    handleFormDataChange() {
      this.formIsDirty = true;
      this.validForm = this.$refs.profileForm.validate();
    },

    saveUser() {
      this.normalizePhone();
      this.subirImagen();

      this.$http
        .post(saveProfileUrl, { id: this.authUser.id, user: this.user }, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.showAlert({ color: 'success', message: this.$t('success.save_user') });
            this.user.password = '';
            this.user.password_repeat = '';
            this.authUser.lang = this.user.lang;
            this.authUser.collegiateNumber = this.user.collegiateNumber;
            this.$i18n.locale = this.user.lang;
            ['es', 'en'].includes(this.user.lang) && this.$moment.locale(this.user.lang);
            this.profileEmail = this.user.email;
            window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
          } else {
            this.alerta.texto =
              response.status === 200 && response.data.estado === 2
                ? this.$t('errors.user_exist')
                : this.$t('errors.try_again');
            this.showAlert({ color: 'error', message: this.alerta.texto });
          }
          this.formIsDirty = false;
        });
    },

    inputGender(value) {
      this.user.gender = value;
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },

    editImage() {
      this.modificando_imagen = true;
      this.estado_croppa = '';
      this.myCroppa.chooseFile();

      setTimeout(function() {
        const element_foto = document.getElementsByClassName('foto_avatar');
        const canvas = element_foto[0].childNodes[4];
        if (typeof canvas !== 'undefined' && canvas !== null) {
          canvas.style.borderRadius = '50%';
          canvas.style.boxShadow =
            '0 10px 30px -12px rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2)';
        }
      }, 500);
    },

    onFileSizeExceed() {
      this.$toast.error('La imagen es mayor de 1024KB que es el tamaño máximo permitido');
    },

    subirImagen() {
      if (!this.myCroppa.img) {
        this.modificando_imagen = false;
        this.estado_croppa = 'ocultar_croppa';
        return;
      }

      this.disabledButton = true;
      const formData = new FormData();
      formData.append('usuario_id', this.authUser.id);
      formData.append('user_id', this.authUser.id);
      formData.append('from', 'usuario');
      this.myCroppa.generateBlob(blob => {
        formData.append('file', blob, 'filename.png');
        this.$http.post(editImageUserUrl, formData, { headers: getHeaderMultimedia() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.user.fotoUrl = response.data.imagen;
            this.updateAvatarImg(this.user.fotoUrl);
            this.showAlert({
              color: 'success',
              message: 'La Imagen se ha modificado correctamente',
            });
            this.modificando_imagen = false;
            this.estado_croppa = 'ocultar_croppa';
            this.myCroppa.img = null;
            this.authUser.img = this.user.fotoUrl;
            window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
          } else {
            this.showAlert({ color: 'error', message: this.$t('errors.try_again') });
          }
          this.disabledButton = false;
        });
      });
    },

    onFileChange() {
      this.disabledButton = true;
      const nuevaimagen = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append('file', nuevaimagen);
      formData.append('usuario_id', this.user.id);
      formData.append('user_id', this.authUser.id);
    },

    removeEspecialidad(item) {
      this.user.especialidades.splice(this.user.especialidades.indexOf(item), 1);
      this.user.especialidades = [...this.user.especialidades];
      canPrescribe(this.especialidades, this.user.especialidades);
    },

    closeAfterSelect() {
      this.$refs.select.isMenuActive = false;
    },

    checkHideCollegiateNumber() {
      const user = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      if (typeof user !== 'undefined' && user.nombreEmpresa !== '' && user.nombreEmpresa.toUpperCase() === 'COFENAT') {
        this.hideCollegiateNumber = true;
      }
    },

    updateAvatarImg(newImage) {
      const imgAvatar = document.getElementsByClassName('d-avatar');
      if (imgAvatar[0] !== null) {
        imgAvatar[0].setAttribute('src', newImage);
      }

      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
      authUser.img = newImage;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
    },

    normalizePhone() {
      if (this.isPatient) {
        if (this.user.phone.length > 11) {
          const phone_prefix = this.user.phone.substring(0, this.user.phone.indexOf(' '));
          let phone = this.user.phone.substring(this.user.phone.indexOf(' '));
          phone = this.clearString(phone);
          this.user.phone = phone;
          this.user.phone_prefix = phone_prefix;
        }
      }
    },

    clearString(str) {
      return str.replace(/ +/g, '').replace(/-/g, '');
    },

    handleIdErrors() {
      this.$nextTick(() => {
        this.validForm = false;
      });
    },

    setIdDocument(data) {
      this.validForm = true;
      this.user.id_document = data.idDocument;
      this.user.id_document_type = data.idDocumentType;
    },

    validatePhone(phone) {
      phone = this.clearString(phone);
      let regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      if (phone.length === 9) {
        regex = /^[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      }

      const match = regex.exec(phone);
      if (!match) {
        return false;
      }
      return true;
    },

    validate() {
      this.validForm = this.$refs.profileForm.validate();

      if (!this.validForm) {
        this.showAlert({ color: 'warning', message: this.$t('errors.form_invalid') });
        return;
      }
      this.requestPassword();
    },

    requestPassword() {
      if (this.profileEmail.trim() !== this.user.email.trim()) {
        this.dialogPassword.show = true;
        return;
      }
      this.saveUser();
    },

    checkPassword() {
      if (this.dialogPassword.password !== '') {
        const data = {
          userId: this.authUser.id,
          pass: this.dialogPassword.password,
        };

        this.$http.post(checkPassword, data, { headers: getHeader() }).then(res => {
          if (res.status === 200 && res.data.success) {
            this.dialogPassword.show = false;
            this.dialogPassword.password = '';
            this.saveUser();
          } else {
            this.$toast.error(this.$t('errors.usr_pw_incorrect'));
          }
        });
      } else {
        this.$toast.error(this.$t('message.intro_pw'));
      }
    },

    cancelCheckPassword() {
      this.dialogPassword.show = false;
      this.dialogPassword.password = '';
    },

    passwordChangedSuccessfully(message) {
      this.showAlert({ color: 'success', message: message });
    },

    changePasswordError(message) {
      this.showAlert({ color: 'error', message: message });
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.formIsDirty && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="scss">
.user-profile {
  &__loader {
    height: 100%;
  }
}

.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_datos_personales {
  margin-top: 40px;
}
.font-red {
  color: #bc2b36;
}

.btn_upload_imagen {
  position: absolute !important;
  z-index: 100;
  top: 135px;
  margin-left: 60px;
  margin-top: 62px;
  -webkit-animation: enfasis_btn 1s infinite alternate;
  animation: enfasis_btn 1s infinite alternate;
}

.foto_usuario {
  cursor: pointer;
}

.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}

.foto_avatar {
  position: relative;
  z-index: 1;
}

.ocultar_croppa {
  display: none;
}

.langs {
  display: inline-block;
}

.vue-tel {
  width: 98%;
  line-height: 2.9em;
  border: 1px solid #a39e9e;
}

.vue-tel:hover {
  border: 1px solid rgba(0.25, 0.8, 0.5, 1);
}

.avatar-image {
  object-fit: cover;
}

@media (max-width: 599px) {
  .vue-tel {
    width: 99%;
    margin-bottom: 2em;
  }
}
</style>
